let Config = {
    options: {
        rootUrl: $("meta[name='_root_url']").attr("content"),
        rootStaticUrl: $("meta[name='_root_static_url']").attr("content"),
        regionAndLanguage: $("meta[name='_region_language']").attr("content"),
        domain: 'backoffice'
    }
};

export default Config;
