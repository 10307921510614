import $ from "jquery";
import Config from './modules/config'
import Login from './modules/login'
import Utility from './modules/utility'

window.Login = Login;
window.Config = Config;
window.Utility = Utility;

$(function () {
    Login.init();
    Utility.init();
});
