let Utility = {
    init: () => {
        initDataTriggeredEvents();
    }
};

const initDataTriggeredEvents = () => {
    $(document).on('click', '[data-toggle]', (event) => {
        const toggledElementId = event.currentTarget.dataset.toggle || event.currentTarget.id;
        if (toggledElementId) $(`#${toggledElementId}`).toggleClass('d-none');
    });
}

export default Utility;
