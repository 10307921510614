let Login = {
    init: () => {
        Login.loginTabs = $('[data-bind="loginTab"]');
        Login.loginTabContainers = $('[data-bind="loginTabContainer"]');

        initLogin();
        // updateCsrfOnSubmit();
    },

    pollSmartId: (pollUrl, successUrl, failureUrl, intervalMillis, fromFail) => {
        window.setTimeout(() => {
            $.ajax({method: "GET", url: pollUrl, dataType: "text", cache: false, headers: {"cache-control": "no-cache"}})
                .done((data) => {
                    if (data === 'RUNNING') {
                        Login.pollSmartId(pollUrl, successUrl, failureUrl, intervalMillis + 500, false);
                    } else if (data === 'SUCCESS') {
                        window.location.href = successUrl;
                    } else {
                        window.location.href = failureUrl;
                    }
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    fromFail ? window.location.href = failureUrl : Login.pollSmartId(pollUrl, successUrl, failureUrl, 1000, true);
                });
        }, intervalMillis);
    }
};

const initLogin = () => {
    if (Login.loginTabs.length) {
        Login.loginTabs.on('click', (e) => {
            let targetId = $(e.currentTarget).attr('href');

            showTab(targetId);
            return false;
        });
    }
};

const showTab = (tabContainerSelector) => {
    Login.loginTabs.removeClass('active');
    Login.loginTabs.filter('[href="' + tabContainerSelector + '"]').addClass('active');

    Login.loginTabContainers.removeClass('active');
    Login.loginTabContainers.filter(tabContainerSelector).addClass('active').find('INPUT[type="email"]').focus();
};

const updateCsrfOnSubmit = () => {
    let forms = document.querySelectorAll('[data-bind="csrfReload"]');

    if (!forms.length) return;

    forms.forEach(form => {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            fetch(Config.options.rootUrl + "csrf")
                .then(res => res.ok ? res.json() : console.error('Fetching CSRF failed!'))
                .then(data => {
                    console.log(data);
                   /* form.querySelector(`input[name='${data.parameterName}']`).value = data.token
                    form.submit();*/
                });
        });
    })
}

export default Login;
